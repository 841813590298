<template>
    <div>
      <a-modal
          :title="modeltitle"
          :visible="visible"

          @ok="uppassOk()"
          @cancel="visible=false"
          style="width: 200px"

      >
        <a-form-model layout="inline">
          <a-form-model-item label="账号"  >
            <a-input style="width: 200px"  v-model="admin.UserId" placeholder="账号">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="企业"  >
            <a-select  v-model="admin.CompanyId" allowClear style="width: 200px;"   placeholder="选择企业">

              <a-select-option v-for="(item) in companylist" :value="item.Id">
                {{item.Name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="是否私人订制"  >
            <a-select  v-model="admin.CategoryId" allowClear style="width: 200px;"   placeholder="是否私人订制">
              <a-select-option value="1">
                是
              </a-select-option>
              <a-select-option value="0">
              否
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="密码"  >
            <a-input-password style="width: 200px"  v-model="admin.Password" placeholder="密码">

            </a-input-password >
          </a-form-model-item>
          <a-form-model-item label="二维码"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadImage"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.QrCode" placeholder="二维码">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="海报"  >
            <a-upload
                style=""
                :file-list="fileList"
                name="file"
                action
                :customRequest="uploadPosters"

            >
              <a-button>
                <upload-outlined></upload-outlined>
                点击上传
              </a-button>
            </a-upload>
            <a-input disabled="disabled" v-model="admin.Posters" placeholder="海报">

            </a-input >
          </a-form-model-item>
          <a-form-model-item label="是否后台录入"  >
            <a-select  v-model="admin.IsEntry" allowClear style="width: 200px;"   >


              <a-select-option value="1">
                是
              </a-select-option>


            </a-select>
          </a-form-model-item>
          <a-form-model-item label="过期时间"  >
            <a-date-picker v-model:value="admin.EndDate" picker="year" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
        <a-layout>

            <a-layout style="background: #fff;padding: 0;margin:0;padding: 16px;">

              <a-form-model layout="inline" :model="queryParam" >
    <a-row style="margin:16px;">
      <a-col :span="2">
        <a-button @click="showModel">新增</a-button>
      </a-col>
       
        <a-col :span="4">
                                
                                <a-form-model-item label="会员名称" >
                                    <a-input v-model="queryParam.username" placeholder="会员名称">

                                    </a-input>
                                </a-form-model-item>
                               

                            </a-col>
                            <a-col :span="6">
                              <a-form-model-item label="上级会员名称" >
                                    <a-input v-model="queryParam.parentUserId" placeholder="上级会员名称">

                                   </a-input>
                                </a-form-model-item>
                                <a-form-model-item>
                                    <a-button @click="handleSubmit" type="default" icon="search">
                                        搜索
                                    </a-button>
                                </a-form-model-item>
                            </a-col>
    </a-row>
    </a-form-model>


            </a-layout>

            <a-layout style="background: #fff;padding: 0;margin:16px 0;padding: 16px;">

                <a-table size="small" :rowKey="record => record.Id" :pagination="pagination"   @change="handleTableChange" :columns="columns" :data-source="data" bordered>
                        <template slot="action" slot-scope="text, record">
                          <a-space>
                                <span>
                          <a @click="editclomun(record.Id)">编辑</a>
                        </span>
                            <span>
                          <a @click="deleteclomun(record.Id)">删除</a>
                        </span>
                          </a-space>
                        </template>

                </a-table>
            </a-layout>
        </a-layout>
    </div>

</template>

<script>
    export default {
        name: "index",
        data(){
            return {
                modeltitle:'',
                visible:false,
              companylist:[],
                pagination: {
                    total: 0,
                    pageSize: 18,//每页中显示10条数据
                    showSizeChanger: true,
                    pageSizeOptions: ["18", "20", "50", "100"],//每页中显示的数据
                    showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
                },
                topsize:'large',
                queryParam:{
                    page:1,
                    size:18,
                    username:'',
                    parentUserId:''

                },

              admin:{},
                roleOption:[

                ],
                fileList:[
          ],
                columns:[

                    {
                        title: '账号',
                        dataIndex: 'UserId',
                    },

                    {
                        title: '密码',

                        dataIndex: 'Password',
                        customRender:function (text, record, index) {
                          return "******";

                    }
                    },
                    {
                        title: '昵称',
                        dataIndex: 'NickName'

                    },
                  {
                    title: '是否后台录入',
                    dataIndex: 'IsEntry',
                    customRender:function (text, record, index) {
                      if (text==1){
                        return '是';
                      }else{
                        return '否';
                      }

                    }

                  },
                  {
                    title: '上级帐号',
                    dataIndex: 'ParentUserId'

                  },
                    {
                        title: '创建时间',
                        dataIndex: 'DateAndTime'

                    },
                  {
                    title: '过期时间',
                    dataIndex: 'EndDate'

                  },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        scopedSlots:{ customRender: 'action' }

                    },
                ],
                data:[]
            }
        },
        mounted(){

            this.GetPageList();
            this.InitCompanys();
        },
        methods:{
          InitCompanys(){
            let that=this;
            that.roleOption=[];
            that.$axios.post("/api/SourceDown/GetCompanyList", null).then((res) => {
              if (res.data.message=="success"){
                that.companylist = res.data.data;
              }
            });
          },
            showModel(){
                    this.visible=true;this.modeltitle='新增';this.admin={}
            },
            handleSubmit(){
                this.GetPageList();
            },


            handleTableChange(pagination){
                this.pagination.current = pagination.current;
                this.pagination.pageSize = pagination.pageSize;
                this.queryParam.page = pagination.current;
                this.queryParam.size = pagination.pageSize;
                this.GetPageList();
            },
          OnlineChange(date,dateString){
            if (dateString.length>0)
              this.admin.OnlineDate=dateString[0]?this.$moment(dateString[0]).format('YYYY-MM-DD'):'';
          },
            GetPageList(){
                let that=this;
                that.data=[];
                that.pagination.total=0;


                this.$axios.post("/api/SourceDown/GetMemberList", that.queryParam).then((res) => {
                    if (res.data.message=="success"){
                        that.pagination.total = res.data.data.Total;
                        that.data = res.data.data.Data;

                    }

                });

            },
          editclomun(id){
            let that=this;


            that.admin={};
            that.modeltitle='修改';
            that.visible=true;
            that.$axios.post("/api/SourceDown/GetMember", {Id:id}).then((res) => {
              if (res.data.message=='success'){
                that.admin=res.data.data;
              }

            });

          },
            deleteclomun(id){
                let that=this;

                    that.$axios.post("/api/SourceDown/DeleteMember", {Id:id}).then((res) => {

                        if (res.data.message=='success'){
                            that.data=that.data.filter((item)=>{return item.Id!=id});
                            that.$message.success('删除成功!');

                        }else{
                            that.$message.error('删除失败!');
                        }
                    });


            },
            uppassOk(){
                let that=this;
                let url;
                if (that.modeltitle=='新增'){
                    url="/api/SourceDown/AddMember";
                }else {
               
              url="/api/SourceDown/UpdateMember";
                }
                
                that.$axios.post(url, that.admin).then((res) => {

                    if (res.data.message=='success'){
                        this.visible=false;
                        that.$message.success('操作成功!');
                        that.GetPageList();

                    }else{
                        that.$message.error('操作失败!');
                    }
                });
            },
          uploadImage(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'QrCode',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
          },
          uploadPosters(file){
            let _this = this,
                data = file.file,
                fileParams = new FormData();
            fileParams.append('file', data);
            this.$axios.post("/api/SourceDown/Upload", fileParams,{
              headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
              if (res.data.message == 'success') {
                _this.$set( _this.admin,'Posters',res.data.data[0]);
              }else {
                return _this.$message.error(res.data.message);
              }
            });
          }

        }
    }
</script>

<style scoped>

</style>